var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Add orders to the contract","show":_vm.show,"size":"lg"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex w-100"},[_c('TMessage',{attrs:{"content":"Selected: "},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(_vm._s(_vm.ordersSelected.length))]},proxy:true}])}),(_vm.ordersSelected.length)?_c('CLink',{staticClass:"ml-2 d-flex",on:{"click":function($event){_vm.ordersSelected = []}}},[_vm._v(" ("),_c('TMessage',{attrs:{"content":"Deselect all","bold":""}}),_vm._v(") ")],1):_vm._e(),_c('TButton',{staticClass:"ml-auto",attrs:{"options":{ disabled: _vm.lodash.isEmpty(_vm.ordersSelected) || _vm.loading },"variant":"outline","content":"Add to contract","icon":"cil-check"},on:{"click":_vm.submitOrdersSelected}})],1)]},proxy:true}])},[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"order.order_available_for_contract","resource":"","reloadable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.fetch},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"m-auto"},[_c('TInputCheckbox',{staticClass:"ml-2",attrs:{"checked":_vm.ordersSelected.includes(item.id)},on:{"change":function($event){return _vm.selectOrder(item.id)}}})],1)]}},{key:"trackings",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.trackings.length > 0)?_c('TMessage',{attrs:{"content":item.trackings[0].code,"noTranslate":""}}):_c('TMessageNotFound')],1)]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":""}})],1)]}},{key:"shipment_method_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageShipmentMethod',{attrs:{"id":item.shipment_method_id}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Order Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"trackings-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['by_tracking_code'],"placeholder":"Tracking Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'by_tracking_code', $event)},_vm.filterChange]}})]},proxy:true},{key:"shipment_method_id-filter",fn:function(){return [_c('SSelectShipmentMethod',{attrs:{"value":_vm.filter.shipment_method_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "shipment_method_id", $event)},"change":_vm.filterChange}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }