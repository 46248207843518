var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center flex-wrap"},[_c('strong',[_vm._v(_vm._s(_vm.detail.id))]),_c('SMessageContractStatus',{staticClass:"ml-1",attrs:{"status":_vm.detail.status_id}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),(_vm.showBtn)?_c('div',{staticClass:"ml-auto"},[(_vm.detail.service_fee_unsolved || _vm.detail.purchase_cost_unsolved)?_c('SButtonDebit',{staticClass:"ml-1",attrs:{"options":{ disabled: _vm.loading }},on:{"click":_vm.debit}}):_vm._e(),_c('TButton',{staticClass:"ml-1",attrs:{"size":"sm","content":_vm.contentBtnContract,"color":_vm.colorBtnContract,"options":{ disabled: _vm.loading }},on:{"click":_vm.changeStatusContract}}),_c('TButtonRefresh',{staticClass:"ml-1",attrs:{"options":{ disabled: _vm.loading }},on:{"click":_vm.refresh}})],1):_vm._e()],1),_c('CCardBody',[_c('CRow',{staticClass:"mb-4"},[_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4' }}},[_c('TTableAsForm',{staticClass:"mb-4",attrs:{"title":"Details","data":_vm.detail,"fields":_vm.detailFields,"splitLeft":7},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value,"editable":_vm.editable},on:{"change":_vm.updateContractId}})]}},{key:"customer",fn:function(ref){
var value = ref.value;
return [_c('TLink',{attrs:{"content":_vm.detail.customer ? value.name : _vm.detail.customer_id,"to":_vm.lodash.getReferenceLink('user', _vm.detail.customer_id),"messageOptions":{ bold: true }}})]}},{key:"sales_organization_id",fn:function(ref){
var value = ref.value;
return [_c('SMessageOrganization',{attrs:{"id":value}})]}},{key:"transport_organization_id",fn:function(ref){
var value = ref.value;
return [_c('SMessageOrganization',{attrs:{"id":value}})]}},{key:"created_at",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"dateOnly":""}})]}},{key:"updated_at",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"dateOnly":""}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4' }}},[_c('TTableAsForm',{staticClass:"mb-4",attrs:{"title":"Dates","data":_vm.detail,"fields":_vm.dateFields,"splitLeft":7},scopedSlots:_vm._u([{key:"start_date",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"dateOnly":"","editable":_vm.editable},on:{"change":function($event){return _vm.updateContract('start_date', $event)}}})]}},{key:"end_date",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"dateOnly":"","editable":_vm.editable},on:{"change":function($event){return _vm.updateContract('end_date', $event)}}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4' }}},[_c('TTableAsForm',{staticClass:"mb-4",attrs:{"title":"Compensation","data":_vm.detail,"fields":_vm.compensationFields,"splitLeft":7},scopedSlots:_vm._u([{key:"compensation",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"compensated",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"compensation_unsolved",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4' }}},[_c('TTableAsForm',{staticClass:"mb-4",attrs:{"title":"Goods money","data":_vm.detail,"fields":_vm.goodsMoneyFields,"splitLeft":7},scopedSlots:_vm._u([{key:"purchase_cost",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"purchase_cost_paid",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"purchase_cost_unsolved",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"purchase_cost_debited",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"purchase_cost_outstanding",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"additional_cost",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4' }}},[_c('TTableAsForm',{staticClass:"mb-4",attrs:{"title":"Service charge","data":_vm.detail,"fields":_vm.serviceChargeFields,"splitLeft":7},scopedSlots:_vm._u([{key:"service_fee",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"service_fee_paid",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"service_fee_unsolved",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"service_fee_debited",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"service_fee_outstanding",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"fields":_vm.freightChargeFields,"data":_vm.detail,"splitLeft":7,"title":"Freight charge"},scopedSlots:_vm._u([{key:"freight_charge",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"freight_charge_debited",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"freight_charge_outstanding",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"freight_charge_paid",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"freight_charge_unsolved",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"discount",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"fields":_vm.otherCostFields,"data":_vm.detail,"splitLeft":7,"title":"Other fees"},scopedSlots:_vm._u([{key:"buying_agent_fee",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"cod_cost",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"special_declaration",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"insurance_declaration",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }