var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"h-100 m-0"},[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"Order list","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),(_vm.showBtn)?_c('div',{staticClass:"ml-auto"},[(_vm.isContractOpen)?_c('TButton',{attrs:{"content":"Add order","variant":"outline","size":"","icon":"cil-plus"},on:{"click":function($event){_vm.showModalAdd = true}}}):_vm._e()],1):_vm._e()],1),(_vm.list)?_c('CCardBody',{staticClass:"w-100 mx-auto",staticStyle:{"max-width":"800x"}},[_c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"order.orders","resource":"","reloadable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.fetch},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-auto"},[_c('TButtonRemove',{staticClass:"mx-auto",attrs:{"options":{ disabled: !_vm.isContractOpen }},on:{"click":function($event){return _vm.removeOrder(item.id)}}})],1)]}},{key:"trackings",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.trackings.length > 0)?_c('SMessageTracking',{attrs:{"value":item.trackings[0].code,"checked":item.trackings[0].received}}):_c('TMessageNotFound')],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrderStatus',{attrs:{"id":item.status_id}})],1)]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.id)?_c('TLink',{attrs:{"content":item.id,"to":_vm.lodash.getReferenceLink(
               'order',
                item.id
              )}}):_vm._e(),_c('SMessageOrderType',{attrs:{"id":item.type_id}})],1)]}},{key:"shipment_method_id",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('SMessageShipmentMethod',{attrs:{"id":item.shipment_method_id}})],1)]}},{key:"purchase_cost",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"purchase_cost_paid",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_paid,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"purchase_cost_outstanding",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_outstanding,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"purchase_cost_debited",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_debited,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"purchase_cost_unsolved",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_unsolved,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"service_fee",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee,"currency":item.service_fee_currency_id}})],1)]}},{key:"service_fee_paid",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee_paid,"currency":item.service_fee_currency_id}})],1)]}},{key:"service_fee_outstanding",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee_outstanding,"currency":item.service_fee_currency_id}})],1)]}},{key:"service_fee_debited",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee_debited,"currency":item.service_fee_currency_id}})],1)]}},{key:"service_fee_unsolved",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee_unsolved,"currency":item.service_fee_currency_id}})],1)]}},{key:"compensation",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.compensation,"currency":item.service_fee_currency_id}})],1)]}},{key:"compensated",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.compensated,"currency":item.service_fee_currency_id}})],1)]}},{key:"compensation_unsolved",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.compensation_unsolved,"currency":item.service_fee_currency_id}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Order Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"customer_id-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.customer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "customer_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"trackings-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['by_tracking_code'],"placeholder":"Tracking Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'by_tracking_code', $event)},_vm.filterChange]}})]},proxy:true},{key:"shipment_method_id-filter",fn:function(){return [_c('SSelectShipmentMethod',{attrs:{"value":_vm.filter.shipment_method_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "shipment_method_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"status-filter",fn:function(){return [_c('SSelectOrderStatus',{attrs:{"store":"order.statuses","value":_vm.filter.status_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status_id", $event)},"change":_vm.filterChange}})]},proxy:true}],null,false,275860839)}),_c('AddOrder',{attrs:{"show":_vm.showModalAdd,"customerId":_vm.customerId,"contract":_vm.contract},on:{"update:show":function($event){_vm.showModalAdd=$event},"updated":_vm.addOrder}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }