var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"h-100 m-0"},[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"Commodity information","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}})],1),(_vm.data)?_c('CCardBody',[_c('div',[_c('TTableSimple',{attrs:{"fields":_vm.fields,"items":_vm.data,"resource":"","size":"sm","columnFilter":"","pagination":true,"options":{ striped: true, itemsPerPage: 30 }},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TButtonRemove',{staticClass:"mx-auto",attrs:{"tooltip":"Remove the order from the contract","options":{ disabled: !_vm.isContractOpen }},on:{"click":function($event){return _vm.removeOrder(item.order_id)}}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.product)?_c('SCardProductItems',{staticClass:"text-wrap text-break",staticStyle:{"width":"300px","font-size":"0.95rem"},attrs:{"widthAuto":"","item":item.product,"resource":"/sale/goods/products"}}):_vm._e()],1)]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price,"currency":_vm.contract.purchase_cost_currency_id}})],1)]}},{key:"discount_tax",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.discount_tax,"currency":_vm.contract.purchase_cost_currency_id}})],1)]}},{key:"tax_percent",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessagePercent',{attrs:{"value":item.tax_percent}})],1)]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.order_id)?_c('TLink',{attrs:{"content":item.order_id,"to":_vm.lodash.getReferenceLink(
                'order',
                item.order_id
              )}}):_vm._e()],1)]}},{key:"quantity",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.quantity}})],1)]}},{key:"quantity_items",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.product)?_c('TMessageNumber',{attrs:{"value":item.product.quantity_items}}):_vm._e()],1)]}},{key:"boxes_quantity",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.product)?_c('TMessageNumber',{staticClass:"font-weight-bold",attrs:{"value":item.quantity / item.product.quantity_items}}):_vm._e()],1)]}},{key:"weight",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.product)?_c('TMessageNumber',{attrs:{"value":item.product.weight,"suffix":"kg"}}):_vm._e()],1)]}},{key:"height",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.product)?_c('TMessageNumber',{attrs:{"value":item.product.height,"suffix":"cm"}}):_vm._e()],1)]}},{key:"width",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.product)?_c('TMessageNumber',{attrs:{"value":item.product.width,"suffix":"cm"}}):_vm._e()],1)]}},{key:"length",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.product)?_c('TMessageNumber',{attrs:{"value":item.product.length,"suffix":"cm"}}):_vm._e()],1)]}},{key:"total_weight",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.product)?_c('TMessageNumber',{attrs:{"value":(item.product.weight * item.quantity) /
                item.product.quantity_items,"suffix":"kg"}}):_vm._e()],1)]}},{key:"volume",fn:function(ref){
                var item = ref.item;
return [_c('td',[(item.product)?_c('TMessageVolume',{attrs:{"value":(item.product.width *
                  item.product.height *
                  item.product.length *
                  item.quantity) /
                  item.product.quantity_items || 0}}):_vm._e()],1)]}},{key:"note",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"noTranslate":""}})],1)]}},{key:"sku",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_vm._l((item.boxes),function(box){return _c('div',{key:box.id},[_c('TMessageText',{attrs:{"value":box.id}})],1)}),(!item.boxes.length)?_c('TMessageNotFound'):_vm._e()],2)]}},{key:"payment_due_date",fn:function(ref){
                  var item = ref.item;
return [_c('td',_vm._l((item.distributions),function(dis){return _c('div',{key:dis.id},[(dis.purchase_item)?_c('TMessageDateTime',{attrs:{"content":dis.purchase_item.payment_due_date,"dateOnly":""}}):_vm._e()],1)}),0)]}},{key:"expected_delivery",fn:function(ref){
                  var item = ref.item;
return [_c('td',_vm._l((item.distributions),function(dis){return _c('div',{key:dis.id},[(dis.purchase_item)?_c('TMessageDateTime',{attrs:{"content":dis.purchase_item.expected_delivery,"dateOnly":""}}):_vm._e()],1)}),0)]}},{key:"balance",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":_vm.contract.purchase_cost_currency_id}})],1)]}},{key:"result-volume",fn:function(){return [_c('th',[_c('div',{staticClass:"d-flex text-primary"},[_c('TMessage',{attrs:{"content":"Total:"}}),_c('i',[_c('TMessageVolume',{staticClass:"ml-1",attrs:{"value":_vm.getTotalVolume()}})],1)],1)])]},proxy:true},{key:"result-total_weight",fn:function(){return [_c('th',[_c('div',{staticClass:"d-flex text-primary"},[_c('TMessage',{attrs:{"content":"Total:"}}),_c('i',[_c('TMessageNumber',{staticClass:"ml-1",attrs:{"value":_vm.getTotalWeight(),"suffix":"kg"}})],1)],1)])]},proxy:true},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Order Id","value":_vm.order_id},on:{"update:value":function($event){_vm.order_id=$event}}})]},proxy:true},{key:"item-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Product Id","value":_vm.product_id},on:{"update:value":function($event){_vm.product_id=$event}}})]},proxy:true},{key:"_-filter",fn:function(){return [_c('TButtonClear',{staticClass:"mb-1 ml-2",attrs:{"variant":"outline"},on:{"click":_vm.clearFilter}})]},proxy:true}],null,false,3686257534)})],1)]):_vm._e(),_c('AddOrder',{attrs:{"show":_vm.showModalAdd,"customerId":_vm.contract.customer_id,"contract":_vm.contract},on:{"update:show":function($event){_vm.showModalAdd=$event},"updated":_vm.refreshContract}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }